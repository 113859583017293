import React from "react";
import {Box, Heading, Link} from "theme-ui";
import {Link as GatsbyLink} from "gatsby"
import {Helmet} from "react-helmet"

const IndexPage = () => {
    return (
        <>
            <Helmet>
                <title>Karl Erik Hofseth</title>
                <meta name="description" content="My personal webpage"/>
            </Helmet>
            <main>
                <Heading variant="styles.h3">Hi!</Heading>
                <Box as="p" variant="styles.p">I'm Karl Erik Hofseth, a Norwegian CompSci student at NTNU, an avid gamer, and a freelance developer sometimes.</Box>
                <Box as="p" variant="styles.p">I sometime have things to say at the blog <Link as={GatsbyLink} to="/blog">over here.</Link></Box>
            </main>
        </>
    )
}
export default IndexPage